import React, { Component } from "react"
import "../styles/style.css"
import "../css/jquery.dataTables.css"

export default class Tbl extends Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      const $ = require("jquery")
      $.Datatable = require("datatables.net")

      console.log(this.el)
      this.$el = $(this.el)
      this.$el.DataTable({
        data: this.props.data,
        columns: [
          { title: "No Invoice" },
          { title: "No.Resi" },
          { title: "Nama" },
        ],
      })
    }
  }

  render() {
    return (
      <div>
        <table
          border={"1px"}
          className="display"
          width="100%"
          ref={el => (this.el = el)}
        ></table>
      </div>
    )
  }
}
